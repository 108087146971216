

<header class="navbar-fixed">
  <div class="toolbar-inner-area sticky-navbar" [ngClass]="{'sticky': sticky}" (scroll)="onElementScroll($event)" data-sticky-offset="50">
    <div class="holder">
      <div class="hr">
        <div class="vc">
          <div class="toolbar_contact align-right">
            <div class="pms_connection">



            </div>

            <div class="contact_phone">

              <a href="tel:+{{token.getProperty().mobile}}" class="wp-style" style="font-weight: 600;">{{token.getProperty().mobile}}</a>

            </div>
            <div class="toolbar_social_area">
              <ul class="icon_list">
                <li >
                  <a  href="https://api.whatsapp.com/send/?phone=%2B{{token.getProperty().mobile}} &text=www.hotelroyalpalacemeerut.com" target="_blank" title="">
                    <i class="fa fa-whatsapp fa-2x" style="background-color: lightgreen;border-radius: 50px; padding: 5px 8px;"></i>
                  </a>
                </li>
                <li *ngIf="token.getProperty().facebook">
                  <a href="https://fb.com/{{ token.getProperty().facebook }}" target="_blank"
                  title="">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
               <li *ngIf="token.getProperty().instagram">
                  <a href="https://instagram.com/{{ token.getProperty().instagram }}" target="_blank" title="">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>

                <!-- <li>
                                  <a href=""><i class="fa fa-skype"></i></a>
                              </li>
                              <li>
                                  <a href=""><i class="fa fa-pinterest-square"></i></a>
                              </li> -->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-navbar></app-navbar>
</header>
