<!-- <div class="hotel_rooms_area">
  <div class="holder">
    <div class="hr">
      <div class="vc">
        <div class="section_title align-center"> -->
          <!-- <div class="section_sub_title">
            <p> hotel</p>
          </div> -->
          <!-- <h1>hotel rooms</h1>
        </div>
      </div>
    </div>
    <div class="hr">
      <div class="vc-sd-12">
        <div class="hotel_room_inner">
            <div class="room-carousel-type1 owl-carousel">

                <div class="item" *ngFor="let room of rooms;" >
                    <div class="single_hotel_room align-center mb-30">
                        <div class="single_hotel_room_top">
                          <img *ngIf="room.imageList?.length" src="{{ room.imageList[0].url}}"
                          alt=""> -->
                            <!-- <a href="booking.php" class="book_btn common_btn2 btn_hbreffect1">book now</a> -->
                        <!-- </div>
                        <div class="single_hotel_room_bottom">
                            <h3>{{room.name}}</h3>
                            <h3 class="numb"><span>₹</span>{{room.roomOnlyPrice}} </h3>per night
                            <p>Accommodates upto
                              {{room.maximumOccupancy}} pax, No. Of Rooms {{room.noOfRooms}}</p> -->
                            <!-- <div class="bottom_icon">
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                                <i class="fa fa-star"></i>
                            </div> -->
                    <!-- </div>
                </div>


            </div>
        </div>
    </div>
</div> -->







<div class="hotel_rooms_area ptb-100-5" >
  <div class="holder">
      <div class="hr">
          <div class="vc">
              <div class="section_title align-center">
                  <div class="section_sub_title">
                      <p style="margin-top: 60px;">luxury hotel</p>
                  </div>
                  <h1>hotel rooms</h1>
              </div>
          </div>
      </div>
      <div class="hr">
          <div class="vc-sd-12">
              <div class="hotel_room_inner">
                  <div class="room-carousel-type1 owl-carousel">

                      <div class="item" *ngFor="let room of rooms;" >
                          <div class="single_hotel_room align-center mb-30">
                              <div class="single_hotel_room_top">
                                <img *ngIf="room.imageList?.length" src="{{ room.imageList[0].url}}"
                                alt="">
                                  <!-- <a href="booking.php" class="book_btn common_btn2 btn_hbreffect1">book now</a> -->
                              </div>
                              <div class="single_hotel_room_bottom">
                                  <h3>Executive Twin Bed Room</h3>
                                  <!-- <h3 class="numb"><span>₹</span>{{token.getProperty().pricePerNight}}</h3> -->
                                  <!-- <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                                      nibh euismod</p> -->
                                  <div class="bottom_icon">
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                      <i class="fa fa-star"></i>
                                  </div>
                              </div>
                          </div>
                      </div>


                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
