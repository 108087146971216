<!-- <div class="warli"  ></div> -->
<div class="footer_area bnr_ftr_overlay">
  <div class="holder">
    <div class="hr">
      <div class="vc-sd-8 vc-sd-offset-2">
        <div class="footer_area_top_inner">
          <div class="footer_text  align-center">
            <h2>Book a room? Take a seat?</h2>
            <p>You can book your room online, and if your room supports it, automatically reserve a seat for dinner in
              our restaurant. So do not wait, book your room now!</p>
          </div>
          <div class="footer_btn  align-center">
            <a href="booking/choose" class="common_btn btn_hbreffect2">book now</a>
          </div>
        </div>
      </div>
      <div class="vc-sd-11 vc-sd-offset-1 vc-od-10 vc-od-offset-1 vc-ld-8 vc-ld-offset-2">
        <ul class="footer_contact">
          <li>
            <div class="footer-phone">
              <span><i class="fa fa-mobile"></i></span>
              <p><a href="tel:+{{token.getProperty().mobile}}">{{token.getProperty().mobile}}</a></p>

            </div>
          </li>
          <li>
            <div class="footer-mail">
              <a
                href="https://www.google.com/maps/place/Hotel+Royal+Palace/@28.995396,77.706186,16z/data=!4m9!3m8!1s0x390c6506f319c085:0x6b7915de29ee2542!5m2!4m1!1i2!8m2!3d28.9953959!4d77.7061861!16s%2Fg%2F11sv6mjn78?hl=en&entry=ttu">
                <span><i class="fa fa-map-marker"></i></span>
                <!-- <p>421/1617/2422, Bajrang Vihar, Patia, Bhubaneswar, Odisha 751024, India
                          </p> -->
                <p>{{token.getProperty().address.streetName}} {{token.getProperty().address.streetNumber}}
                  {{token.getProperty().address.suburb}} {{token.getProperty().address.city}}
                  {{token.getProperty().address.state}} {{token.getProperty().address.country}}
                  {{token.getProperty().address.postcode}} </p>
                <!-- <p>{{property.address}}</p> -->
              </a>
            </div>
          </li>
          <li>
            <div class="footer-info">
              <span><i class="fa fa-window-maximize"></i></span>
              <p><a email_business href="mailto:: hotelroyalpalacemeerutcity@gmail.com"> hotelroyalpalacemeerutcity@gmail.com
                </a>
              </p>


            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="footer_area_bottom">
    <div class="holder">
      <div class="hr">
        <div class="vc-sd-6">
          <div class="copyright-right">
            <p>
              COPYRIGHT @ 2022 ALL RIGHT RESERVED
              <a href="/
                                ">Hotel Royal Palace</a>
            </p>
          </div>
        </div>
        <div class="vc-sd-6">
          <div class="footer_area_bottom_inner align-right">
            <div class="footer_menu">
              <li><p href="/contact" style="align-items: right;">Contact</p></li>

              <!-- <button class="common_btn btn_hbreffect2 mr-3"><a href="https://app.bookonelocal.in/#/sessions/signin" target="_blank">Login to PMS</a></button> -->
              <nav>
                <ul>
                  <!-- <li><a href="booking.php">Booking</a></li>
                                      <li><a href="room2.php">rooms</a></li> -->
                  <!-- <li><a routerLink="/contact">contact</a></li> -->

                </ul>
              </nav>
            </div>
            <!-- <div class="vc-sd-6"> -->
              <!-- <div class="footer_area_bottom_inner align-right"> -->
                  <!-- <div class="footer_menu"> -->
                      <!-- <nav> -->
                          <!-- <ul> -->
                              <!--  <li><a href="booking.php">Booking</a></li> -->
                                  <!-- <li><a href="room2.php">rooms</a></li> -->
                              <!-- <li><a href="/contact" style="align-items: right;">contact</a></li> -->
                          <!-- </ul> -->
                      <!-- </nav> -->
                  <!-- </div> -->



            <div class="footer_social_area">
              <ul class="contact-links">
                <!-- <li class="ml-3">
                  <a href="https://api.whatsapp.com/send/?phone=%2B{{token.getProperty().mobile}} &text=www.hotelroyalpalacemeerut.com"
                    target="_blank" title="">
                    <i class="front whatsapp fa fa-whatsapp"></i>
                    <i class="back front fa fa-whatsapp"></i>
                  </a>
                </li> -->
                <li *ngIf="token.getProperty().facebook" class="ml-3">
                  <a href="https://fb.com/{{ token.getProperty().facebook }}" target="_blank" title=""><i
                      class="front facebook fa fa-facebook"></i>
                    <i class="back fa fa-facebook"></i></a>
                </li>
                <li *ngIf="token.getProperty().instagram" class="ml-3">
                  <a href="https://instagram.com/{{ token.getProperty().instagram }}" target="_blank" title="">
                    <i class=" front instagram fa fa-instagram"></i>
                    <i class="back front fa fa-instagram"></i>
                  </a>
                </li>



                <!-- <li>
                                      <a href="#" target="_blank">
                                          <i class="front fa fa-pinterest-square"></i>
                                          <i class="back fa fa-pinterest-square"></i>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="#" target="_blank">
                                          <i class="front fa fa-skype"></i>
                                          <i class="back fa fa-skype"></i>
                                      </a>
                                  </li> -->

                <!-- <div id="TA_cdsratingsonlywide841" class="TA_cdsratingsonlywide">
                  <ul id="s8roTxK2hn" class="TA_links D12JEwAt1S7">
                    <li id="hFcgEd" class="zhBWN0op"><a target="_blank"
                        href="https://www.tripadvisor.com/Hotel_Review-g608474-d23733644-Reviews-Flora_Ecostay_Lonavala-Lonavala_Pune_District_Maharashtra.html"><img
                          src="https://www.tripadvisor.com/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg"
                          alt="TripAdvisor" /></a></li>
                  </ul>
                </div> -->


              </ul>
            </div>
          </div>



        </div>
      </div>
    </div>
  </div>














</div>
<a routerLink="/top" class="scroll_top" (click)="scroll_top()" data-smooth-scroll data-animate-time="500">
  <i class="fa fa-angle-double-up"></i>

</a>
